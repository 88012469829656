const locale = {
    lang: {
        placeholder: 'Chọn thời điểm',
        rangePlaceholder: ['Từ ngày', 'Đến ngày'],
        today: "Hôm nay",
        now: "Hiện tại",
        ok: "Ok",
        clear: "Xóa",
        month: "Tháng",
        year: "Năm",
        timeSelect: "Chọn giờ",
        dateSelect: "Chọn ngày",
        monthSelect: "Chọn tháng",
        yearSelect: "Chọn năm",
        decadeSelect: "Choose a decade",
        yearFormat: "YYYY",
        dateFormat: "M/D/YYYY",
        dayFormat: "D",
        dateTimeFormat: "M/D/YYYY HH:mm:ss",
        monthFormat: "MMMM",
        monthBeforeYear: true,
        previousMonth: "Tháng trước",
        nextMonth: "Tháng tiếp theo"
    },
    "dateFormat": "YYYY-MM-DD",
    "dateTimeFormat": "YYYY-MM-DD HH:mm:ss",
    "weekFormat": "YYYY-wo",
    "monthFormat": "YYYY-MM"
};

export default locale;