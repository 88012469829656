<template>
    <a-layout-content class="Dashboard">
        <a-breadcrumb style="margin: 16px 24px">
            <a-breadcrumb-item>
                <router-link :to="{ path: '/' }" class="nav-link">
                    Trang chủ
                </router-link>
            </a-breadcrumb-item>
            <a-breadcrumb-item>{{ this.$route.name }}</a-breadcrumb-item>
        </a-breadcrumb>
        <div class="Dashboard" :style="{minHeight: 'calc(100vh - 120px)', backgroundColor: '#fff', margin: '15px 25px', padding: '15px' }">
            <h1>{{ this.$route.name }}</h1>
            <a-divider/>
            <div class="report">
                <div class="report__filter">
                    <div class="filter-item">
                        <span><a-icon type="clock-circle" /> Thời gian: </span>
                        <a-range-picker
                                :locale="locale"
                                :defaultValue="[startDate, currentDate]"
                                @change="onChange"
                                format="YYYYMMDD"
                        />

                        <a-select :showSearch="true" :filterOption="filterOption" placeholder="Chọn quản lý vùng" style="min-width: 180px; margin-left: 20px" @change="onSelectSalonAreaChange">
                            <a-select-option key="all" value="all">Chọn tất cả vùng</a-select-option>
                            <a-select-option v-for="(item, index) in getSalonAreas" :key="index" :value="JSON.stringify(item)">{{ item.fullName }}</a-select-option>
                        </a-select>

                        <a-select v-model="selectedSalon" defaultValue="all" :disabled="disableSelectSalon" showSearch :filterOption="filterOption" placeholder="Chọn ds salon" style="min-width: 150px; margin-left: 20px" @change="onSelectSalonChange">
                            <a-select-option key="all" value="all">Chọn tất cả salon</a-select-option>
                            <a-select-option v-for="item in salonSelects" :key="item.id" :value="item.id">{{ item.shortName }}</a-select-option>
                        </a-select>

                        <a-button style="margin-left: 20px" type="primary" @click="handleGetReport" icon="export">Xem dữ liệu</a-button>
                    </div>
                </div>
                <div class="header__action">
                    <export-excel class="btn btn-default" :data="data" :fields="excelFields" worksheet ="Báo cáo tỉ lệ trả lời đúng theo nội dung" name="bao-cao-ti-le-traloi-theo-noidung.xls">
                        <a-button style="margin-left: 20px" type="primary" icon="export">Xuất file Excel</a-button>
                    </export-excel>
                </div>
            </div>
            <a-divider/>
            <div style="background-color: #ececec; padding: 20px;">
                <h3>Tỉ lệ trả lời đúng trung bình theo nội dung</h3>
                <a-row :gutter="16">
                    <a-col :span="3">
                        <a-card :headStyle="{textAlign: 'center'}" :loading="loading" title="Dịch vụ xuất sắc" :bordered="false">
                            <p style="text-align: center; font-size: 24px;">{{avgDVSXCorrectPercent}}%</p>
                        </a-card>
                    </a-col>
                    <a-col :span="3">
                        <a-card :headStyle="{textAlign: 'center'}" :loading="loading" title="Văn hóa phục vụ" :bordered="false">
                            <p style="text-align: center; font-size: 24px;">{{avgVHPVCorrectPercent}}%</p>
                        </a-card>
                    </a-col>
                    <a-col :span="3">
                        <a-card :headStyle="{textAlign: 'center'}" :loading="loading" title="Bán hàng" :bordered="false">
                            <p style="text-align: center; font-size: 24px;">{{avgSALECorrectPercent}}%</p>
                        </a-card>
                    </a-col>
                    <a-col :span="3">
                        <a-card :headStyle="{textAlign: 'center'}" :loading="loading" title="Chăm sóc khách hàng" :bordered="false">
                            <p style="text-align: center; font-size: 24px;">{{avgCSKHCorrectPercent}}%</p>
                        </a-card>
                    </a-col>
                    <a-col :span="3">
                        <a-card :headStyle="{textAlign: 'center'}" :loading="loading" title="Văn hóa doanh nghiệp" :bordered="false">
                            <p style="text-align: center; font-size: 24px;">{{avgVHDNCorrectPercent}}%</p>
                        </a-card>
                    </a-col>
                    <a-col :span="3">
                        <a-card :headStyle="{textAlign: 'center'}" :loading="loading" title="Sản phẩm/Dịch vụ" :bordered="false">
                            <p style="text-align: center; font-size: 24px;">{{avgSPDVCorrectPercent}}%</p>
                        </a-card>
                    </a-col>
                    <a-col :span="3">
                        <a-card :headStyle="{textAlign: 'center'}" :loading="loading" title="Quy trình/Quy định" :bordered="false">
                            <p style="text-align: center; font-size: 24px;">{{avgQTQDCorrectPercent}}%</p>
                        </a-card>
                    </a-col>
                    <a-col :span="3">
                        <a-card :headStyle="{textAlign: 'center'}" :loading="loading" title="StyNEXT/R30" :bordered="false">
                            <p style="text-align: center; font-size: 24px;">{{avgR30CorrectPercent}}%</p>
                        </a-card>
                    </a-col>
                </a-row>
            </div>
            <a-divider/>
            <a-table :scroll="{x: 'max-content'}" :columns="columns" :dataSource="data" size="middle" bordered :rowKey="(record, index) => index" :loading="loading" :pagination="pagination ">
                <div
                        slot="filterDropdown"
                        slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
                        style="padding: 8px"
                >
                    <a-input
                            v-ant-ref="c => searchInput = c"
                            :placeholder="`Tìm kiếm ${column.title}`"
                            :value="selectedKeys[0]"
                            @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                            @pressEnter="() => handleSearch(selectedKeys, confirm)"
                            style="width: 188px; margin-bottom: 8px; display: block;"
                    />
                    <a-button type="primary" @click="() => handleSearch(selectedKeys, confirm)" size="small" style="width: 90px; margin-right: 8px">Tìm kiếm</a-button>
                    <a-button @click="() => handleReset(clearFilters)" size="small" style="width: 90px">Reset</a-button>
                </div>
                <a-icon
                        slot="filterIcon"
                        slot-scope="filtered"
                        type="search"
                        :style="{ color: filtered ? '#108ee9' : undefined, fontWeight: filtered ? 'bold': undefined }"
                />
                <template slot-scope="text">
                    <div :key="col">
                        <template>{{text}}</template>
                    </div>
                </template>
                <template slot="correctPercent" slot-scope="text, record">
                    <div :key="record.email">
                        <a-tag>{{text}}%</a-tag>
                    </div>
                </template>
                <template slot="avgCorrectPercent" slot-scope="text, record">
                    <div :key="record.email">
                        <a-tag color="#108ee9">{{text}}%</a-tag>
                    </div>
                </template>
                <template slot="avgTimeTurn" slot-scope="text, record">
                    <div :key="record.email">
                        <template>{{record.avgTimeTurnString}}</template>
                    </div>
                </template>
            </a-table>
        </div>
    </a-layout-content>
</template>

<script>
    import moment from 'moment'
    import momentDurationFormatSetup from 'moment-duration-format';
    import locale from '../../configs/datePickerLocale'
    import { mapGetters, mapActions } from 'vuex';
    import { RepositoryFactory } from "@/repository/RepositoryFactory";
    const reportRepository = RepositoryFactory.get('report');
    momentDurationFormatSetup(moment);
    moment.locale("vi-vn")

    export default {
        name: "QuestionGroupReport",
        data() {
            return {
                searchText: '',
                searchInput: null,
                locale,
                startDate: moment().startOf('month'),
                currentDate: moment(),
                salonIds: [],
                data: [],
                disableSelectSalon: true,
                selectedSalon: 'all',
                salonSelects: [],
                loading: false,
                pagination: {
                    pageSize: 15,
                    showTotal: (total, range) => `Hiển thị từ ${range[0]} - ${range[1]} / ${total} dòng`
                },
                excelFields: {
                    "Id": "staffId",
                    "Email": "email",
                    "Họ tên": "fullName",
                    "Vị trí": "staffType",
                    "Level": "skillLevel",
                    "Salon": "salon",
                    "DVSX đã thực hiện": "dvsxTotal",
                    "DVSX trả lời đúng": "dvsxCorrect",
                    "DVSX tỉ lệ trả lời đúng": {
                        field: 'dvsxCorrectPercent',
                        callback: (value) => {
                            return `${value}%`
                        }
                    },
                    "VHPV đã thực hiện": "vhpvTotal",
                    "VHPV trả lời đúng": "vhpvCorrect",
                    "VHPV tỉ lệ trả lời đúng": {
                        field: 'vhpvCorrectPercent',
                        callback: (value) => {
                            return `${value}%`
                        }
                    },
                    "SALE đã thực hiện": "saleTotal",
                    "SALE trả lời đúng": "saleCorrect",
                    "SALE tỉ lệ trả lời đúng": {
                        field: 'saleCorrectPercent',
                        callback: (value) => {
                            return `${value}%`
                        }
                    },
                    "CSKH đã thực hiện": "cskhTotal",
                    "CSKH trả lời đúng": "cskhCorrect",
                    "CSKH tỉ lệ trả lời đúng": {
                        field: 'cskhCorrectPercent',
                        callback: (value) => {
                            return `${value}%`
                        }
                    },
                    "VHDN đã thực hiện": "vhdnTotal",
                    "VHDN trả lời đúng": "vhdnCorrect",
                    "VHDN tỉ lệ trả lời đúng": {
                        field: 'vhdnCorrectPercent',
                        callback: (value) => {
                            return `${value}%`
                        }
                    },
                    "QT/QD đã thực hiện": "qtqdTotal",
                    "QT/QD trả lời đúng": "qtqdCorrect",
                    "QT/QD tỉ lệ trả lời đúng": {
                        field: 'qtqdCorrectPercent',
                        callback: (value) => {
                            return `${value}%`
                        }
                    },
                    "StyNEXT/R30 đã thực hiện": "r30Total",
                    "StyNEXT/R30 trả lời đúng": "r30Correct",
                    "StyNEXT/R30 tỉ lệ trả lời đúng": {
                        field: 'r30CorrectPercent',
                        callback: (value) => {
                            return `${value}%`
                        }
                    },
                    "Tỉ lệ tổng trung bình": {
                        field: 'avgCorrectPercent',
                        callback: (value) => {
                            return `${value}%`
                        }
                    },
                    "Thời gian thực hiện trung bình": "avgTimeTurnString"
                },
                columns: [
                    {
                        title: 'ID',
                        dataIndex: 'staffId',
                        key: 'staffId',
                        width: '1%',
                        scopedSlots: {
                            filterDropdown: 'filterDropdown',
                            filterIcon: 'filterIcon'
                        },
                        onFilter: (value, record) => record.staffId.toString().toLowerCase().includes(value.toLowerCase()),
                        onFilterDropdownVisibleChange: visible => {
                            if (visible) {
                                setTimeout(() => {
                                    this.searchInput.focus();
                                }, 0);
                            }
                        },
                    },
                    {
                        title: 'Họ và tên',
                        dataIndex: 'fullName',
                        key: 'fullName',
                        width: '5%',
                        scopedSlots: {
                            filterDropdown: 'filterDropdown',
                            filterIcon: 'filterIcon'
                        },
                        onFilter: (value, record) => record.fullName.toLowerCase().includes(value.toLowerCase()),
                        onFilterDropdownVisibleChange: visible => {
                            if (visible) {
                                setTimeout(() => {
                                    this.searchInput.focus();
                                }, 0);
                            }
                        },
                    },
                    {
                        title: 'Vị trí',
                        dataIndex: 'staffType',
                        key: 'staffType',
                        width: '3%',
                        sorter: (a, b) => { return a.staffType.toUpperCase().localeCompare(b.staffType.toUpperCase())}
                    },
                    {
                        title: 'Level',
                        dataIndex: 'skillLevel',
                        key: 'skillLevel',
                        width: '3%',
                        sorter: (a, b) => { return a.skillLevel.toUpperCase().localeCompare(b.skillLevel.toUpperCase())}
                    },
                    {
                        title: 'Salon',
                        dataIndex: 'salon',
                        key: 'salon',
                        width: '3%',
                        sorter: (a, b) => { return a.salon.toUpperCase().localeCompare(b.salon.toUpperCase())}
                    },
                    {
                        title: 'DVSX',
                        children: [
                            {
                                title: 'Đã thực hiện',
                                dataIndex: 'dvsxTotal',
                                key: 'DVSXTotal',
                                width: '2%'
                            },
                            {
                                title: 'Trả lời đúng',
                                dataIndex: 'dvsxCorrect',
                                key: 'DVSXCorrect',
                                width: '2%'
                            },
                            {
                                title: 'Tỉ lệ đúng',
                                dataIndex: 'dvsxCorrectPercent',
                                key: 'DVSXCorrectPercent',
                                width: '2%',
                                scopedSlots: { customRender: 'correctPercent' },
                                sorter: (a, b) => { return a.dvsxCorrectPercent - b.dvsxCorrectPercent}
                            }
                        ]
                    },
                    {
                        title: 'VHPV',
                        children: [
                            {
                                title: 'Đã thực hiện',
                                dataIndex: 'vhpvTotal',
                                key: 'VHPVTotal',
                                width: '2%'
                            },
                            {
                                title: 'Trả lời đúng',
                                dataIndex: 'vhpvCorrect',
                                key: 'VHPVCorrect',
                                width: '2%'
                            },
                            {
                                title: 'Tỉ lệ đúng',
                                dataIndex: 'vhpvCorrectPercent',
                                key: 'VHPVCorrectPercent',
                                width: '2%',
                                scopedSlots: { customRender: 'correctPercent' },
                                sorter: (a, b) => { return a.vhpvCorrectPercent - b.vhpvCorrectPercent}
                            }
                        ]
                    },
                    {
                        title: 'SALE',
                        children: [
                            {
                                title: 'Đã thực hiện',
                                dataIndex: 'saleTotal',
                                key: 'SALETotal',
                                width: '2%'
                            },
                            {
                                title: 'Trả lời đúng',
                                dataIndex: 'saleCorrect',
                                key: 'SALECorrect',
                                width: '2%'
                            },
                            {
                                title: 'Tỉ lệ đúng',
                                dataIndex: 'saleCorrectPercent',
                                key: 'SALECorrectPercent',
                                width: '2%',
                                scopedSlots: { customRender: 'correctPercent' },
                                sorter: (a, b) => { return a.saleCorrectPercent - b.saleCorrectPercent}
                            }
                        ]
                    },
                    {
                        title: 'CSKH',
                        children: [
                            {
                                title: 'Đã thực hiện',
                                dataIndex: 'cskhTotal',
                                key: 'CSKHTotal',
                                width: '2%'
                            },
                            {
                                title: 'Trả lời đúng',
                                dataIndex: 'cskhCorrect',
                                key: 'CSKHCorrect',
                                width: '2%'
                            },
                            {
                                title: 'Tỉ lệ đúng',
                                dataIndex: 'cskhCorrectPercent',
                                key: 'CSKHCorrectPercent',
                                width: '2%',
                                scopedSlots: { customRender: 'correctPercent' },
                                sorter: (a, b) => { return a.cskhCorrectPercent - b.cskhCorrectPercent}
                            }
                        ]
                    },
                    {
                        title: 'VHDN',
                        children: [
                            {
                                title: 'Đã thực hiện',
                                dataIndex: 'vhdnTotal',
                                key: 'VHDNTotal',
                                width: '2%'
                            },
                            {
                                title: 'Trả lời đúng',
                                dataIndex: 'vhdnCorrect',
                                key: 'VHDNCorrect',
                                width: '2%'
                            },
                            {
                                title: 'Tỉ lệ đúng',
                                dataIndex: 'vhdnCorrectPercent',
                                key: 'VHDNCorrectPercent',
                                width: '2%',
                                scopedSlots: { customRender: 'correctPercent' },
                                sorter: (a, b) => { return a.vhdnCorrectPercent - b.vhdnCorrectPercent}
                            }
                        ]
                    },
                    {
                        title: 'SP/DV',
                        children: [
                            {
                                title: 'Đã thực hiện',
                                dataIndex: 'spdvTotal',
                                key: 'SPDVTotal',
                                width: '2%'
                            },
                            {
                                title: 'Trả lời đúng',
                                dataIndex: 'spdvCorrect',
                                key: 'SPDVCorrect',
                                width: '2%'
                            },
                            {
                                title: 'Tỉ lệ đúng',
                                dataIndex: 'spdvCorrectPercent',
                                key: 'SPDVCorrectPercent',
                                width: '2%',
                                scopedSlots: { customRender: 'correctPercent' },
                                sorter: (a, b) => { return a.spdvCorrectPercent - b.spdvCorrectPercent}
                            }
                        ]
                    },
                    {
                        title: 'QT/QD',
                        children: [
                            {
                                title: 'Đã thực hiện',
                                dataIndex: 'qtqdTotal',
                                key: 'QTQDTotal',
                                width: '2%'
                            },
                            {
                                title: 'Trả lời đúng',
                                dataIndex: 'qtqdCorrect',
                                key: 'QTQDCorrect',
                                width: '2%'
                            },
                            {
                                title: 'Tỉ lệ đúng',
                                dataIndex: 'qtqdCorrectPercent',
                                key: 'QTQDCorrectPercent',
                                width: '2%',
                                scopedSlots: { customRender: 'correctPercent' },
                                sorter: (a, b) => { return a.qtqdCorrectPercent - b.qtqdCorrectPercent}
                            }
                        ]
                    },
                    {
                        title: 'StyNext/R30',
                        children: [
                            {
                                title: 'Đã thực hiện',
                                dataIndex: 'r30Total',
                                key: 'R30Total',
                                width: '2%'
                            },
                            {
                                title: 'Trả lời đúng',
                                dataIndex: 'r30Correct',
                                key: 'R30Correct',
                                width: '2%'
                            },
                            {
                                title: 'Tỉ lệ đúng',
                                dataIndex: 'r30CorrectPercent',
                                key: 'R30CorrectPercent',
                                width: '2%',
                                scopedSlots: { customRender: 'correctPercent' },
                                sorter: (a, b) => { return a.r30CorrectPercent - b.r30CorrectPercent}
                            }
                        ]
                    },
                    {
                        title: 'Tỉ lệ tổng',
                        children: [
                            {
                                title: 'Đã thực hiện',
                                dataIndex: 'avgTotal',
                                key: 'avgTotal',
                                width: '2%'
                            },
                            {
                                title: 'Trả lời đúng',
                                dataIndex: 'avgCorrect',
                                key: 'avgCorrect',
                                width: '2%'
                            },
                            {
                                title: 'Tỉ lệ đúng',
                                dataIndex: 'avgCorrectPercent',
                                key: 'avgCorrectPercent',
                                width: '2%',
                                scopedSlots: { customRender: 'avgCorrectPercent' },
                                sorter: (a, b) => { return a.avgCorrectPercent - b.avgCorrectPercent}
                            }
                        ]
                    },
                    {
                        title: 'Thời gian trung bình',
                        dataIndex: 'avgTimeTurn',
                        key: 'avgTimeTurn',
                        width: '3%',
                        scopedSlots: { customRender: 'avgTimeTurn' },
                        sorter: (a, b) => {
                            return a.avgTimeTurn - b.avgTimeTurn;
                        }
                    },
                ]
            }
        },
        computed: {
            ...mapGetters('staticData', [
                'getSalons', 'getSalonAreas'
            ]),
            avgDVSXCorrectPercent() {
                if (this.data.length === 0) return 0;
                let sum = this.data.reduce((total, item) => {
                    return total + item.dvsxCorrectPercent;
                }, 0);
                return Math.round( (sum / this.data.length) *100) / 100;
            },
            avgVHPVCorrectPercent() {
                if (this.data.length === 0) return 0;
                let sum = this.data.reduce((total, item) => {
                    return total + item.vhpvCorrectPercent;
                }, 0);
                return Math.round( (sum / this.data.length) *100) / 100;
            },
            avgSALECorrectPercent() {
                if (this.data.length === 0) return 0;
                let sum = this.data.reduce((total, item) => {
                    return total + item.saleCorrectPercent;
                }, 0);
                return Math.round( (sum / this.data.length) *100) / 100;
            },
            avgCSKHCorrectPercent() {
                if (this.data.length === 0) return 0;
                let sum = this.data.reduce((total, item) => {
                    return total + item.cskhCorrectPercent;
                }, 0);
                return Math.round( (sum / this.data.length) *100) / 100;
            },
            avgVHDNCorrectPercent() {
                if (this.data.length === 0) return 0;
                let sum = this.data.reduce((total, item) => {
                    return total + item.vhdnCorrectPercent;
                }, 0);
                return Math.round( (sum / this.data.length) *100) / 100;
            },
            avgQTQDCorrectPercent() {
                if (this.data.length === 0) return 0;
                let sum = this.data.reduce((total, item) => {
                    return total + item.qtqdCorrectPercent;
                }, 0);
                return Math.round( (sum / this.data.length) *100) / 100;
            },
            avgSPDVCorrectPercent() {
                if (this.data.length === 0) return 0;
                let sum = this.data.reduce((total, item) => {
                    return total + item.spdvCorrectPercent;
                }, 0);
                return Math.round( (sum / this.data.length) *100) / 100;
            },
            avgR30CorrectPercent() {
                if (this.data.length === 0) return 0;
                let sum = this.data.reduce((total, item) => {
                    return total + item.r30CorrectPercent;
                }, 0);
                return Math.round( (sum / this.data.length) *100) / 100;
            },
        },
        methods: {
            ...mapActions('authenticate',[
                'refreshToken'
            ]),
            moment,
            onChange(date) {
                this.startDate = date[0];
                this.currentDate = date[1];
            },
            async handleGetReport() {
                if (!this.salonIds.length) {
                    await this.$message.error('Danh sách salon không được bỏ trống!!');
                    return;
                }
                try {
                    this.loading = true;
                    let response = await reportRepository.questionGroupReportStatic({
                        workDateStart: this.startDate.format("YYYYMMDD"),
                        workDateEnd: this.currentDate.format("YYYYMMDD"),
                        salonIds: this.salonIds
                    });

                    if (response.data.meta.success) {
                        this.data = response.data.data.map( item => {
                            let avgCorrect = item.dvsxCorrect + item.vhpvCorrect + item.saleCorrect + item.cskhCorrect + item.vhdnCorrect + item.qtqdCorrect + item.spdvCorrect + item.r30Correct;
                            let avgTotal = item.dvsxTotal + item.vhpvTotal + item.saleTotal + item.cskhTotal + item.vhdnTotal + item.qtqdTotal + item.spdvTotal + item.r30Total;
                            let avgTimeTurn = item.totalTimeTurn === 0 ? 0: Math.round( item.totalTime / item.totalTimeTurn );
                            return {
                                ...item,
                                dvsxCorrectPercent:  item.dvsxTotal === 0 ? 100:Math.round(item.dvsxCorrect / item.dvsxTotal * 100),
                                vhpvCorrectPercent:  item.vhpvTotal === 0 ? 100:Math.round(item.vhpvCorrect / item.vhpvTotal * 100),
                                saleCorrectPercent:  item.saleTotal === 0 ? 100:Math.round(item.saleCorrect / item.saleTotal * 100),
                                cskhCorrectPercent:  item.cskhTotal === 0 ? 100:Math.round(item.cskhCorrect / item.cskhTotal * 100),
                                vhdnCorrectPercent:  item.vhdnTotal === 0 ? 100:Math.round(item.vhdnCorrect / item.vhdnTotal * 100),
                                qtqdCorrectPercent:  item.qtqdTotal === 0 ? 100:Math.round(item.qtqdCorrect / item.qtqdTotal * 100),
                                spdvCorrectPercent:  item.spdvTotal === 0 ? 100:Math.round(item.spdvCorrect / item.spdvTotal * 100),
                                r30CorrectPercent:  item.r30Total === 0 ? 100:Math.round(item.r30Correct / item.r30Total * 100),
                                avgCorrect, avgTotal,
                                avgCorrectPercent: avgTotal === 0 ? 100:Math.round(avgCorrect / avgTotal * 100),
                                avgTimeTurn,
                                avgTimeTurnString: moment.duration(avgTimeTurn, 'seconds').format()
                            }
                        });
                    }
                } catch (e) {
                    if (e.message.includes("401")) {
                        await this.refreshToken();
                        this.handleGetReport();
                    } else {
                        this.openNotification(e.message)
                    }
                } finally {
                    this.loading = false;
                }
            },
            filterOption(inputValue, options) {
                return options.componentOptions.children[0].text.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
            },
            onSelectSalonChange(value) {
                this.salonIds = value === 'all' ? this.salonSelects.map(salon => salon.id):[value];
            },
            onSelectSalonAreaChange(value) {
                if (value === 'all') {
                    this.salonIds = this.getSalons.map(salon => salon.id);
                    this.disableSelectSalon = true;
                } else {
                    this.selectedSalon = 'all';
                    let salonArea = JSON.parse(value);
                    this.salonSelects = this.getSalons.filter( salon => salonArea.salonIds.includes(salon.id));
                    this.salonIds = this.salonSelects.map(salon => salon.id)
                    this.disableSelectSalon = false;
                }
            },
            openNotification (message) {
                this.$notification.open({
                    message: message
                });
            },
            handleSearch(selectedKeys, confirm) {
                confirm();
                this.searchText = selectedKeys[0];
            },

            handleReset(clearFilters) {
                clearFilters();
                this.searchText = '';
            },
        }
    }
</script>

<style scoped>
    .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .header__action {
        display: flex;
        flex-direction: row;
    }
    .filter-item span {
        font-weight: bold;
    }
    .report {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
</style>